<template>
  <div class="teleconsulta-options scroll-container p-3">
    <form @submit.prevent="submitForm">
      <h6>Informações do evento</h6>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Título do evento">
            <b-form-input
              id="title-input"
              v-model="summary"
              @focus="handleFocus($event)"
              @change="updateJson"
              placeholder="Título do evento"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="Identificador de calendário">
            <b-form-select
              id="id-calendar-select"
              v-model="calendarId"
              @change="updateJson"
              :options="calendars"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Descrição">
            <b-form-textarea
              type="text"
              v-model="description"
              @change="updateJson"
              @focus="handleFocus($event)"
              required
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <h6>Data, horários e duração</h6>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Data e horário de início">
            <b-form-input
              id="start-date-input"
              type="text"
              @focus="handleFocus($event)"
              v-model="date_start"
              @change="updateJson"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group label="Data e horário de fim">
            <b-form-input
              id="end-date-input"
              type="text"
              v-model="date_end"
              @focus="handleFocus($event)"
              @change="updateJson"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group label="Duração">
            <b-form-input
              v-model="duration"
              list="duration-options"
              @change="updateJson"
              required
            ></b-form-input>
            <datalist id="duration-options">
              <option
                v-for="option in durationOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </datalist>
          </b-form-group>
        </b-col>
      </b-row>

      <h6>Participantes</h6>
      <b-row class="mb-3">
        <b-col cols="12" md="2">
          <b-form-group label="Máximo de participantes">
            <b-form-input
              type="number"
              v-model="max_participants"
              @focus="handleFocus($event)"
              @change="updateJson"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-for="(attendee, index) in attendees" :key="index">
        <b-col cols="12" md="3">
          <b-form-group label="Nome">
            <b-form-input
              :id="'attendee-name' + index"
              v-model="attendee.name"
              @focus="handleFocus($event)"
              @change="updateJson"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="Telefone">
            <b-form-input
              :id="'attendee-phone' + index"
              v-model="attendee.phone"
              @focus="handleFocus($event)"
              @change="updateJson"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="E-mail">
            <b-form-input
              type="text"
              :id="'attendee-email' + index"
              v-model="attendee.email"
              @focus="handleFocus($event)"
              @change="updateJson"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group label="Categoria">
            <b-form-select
              type="text"
              v-model="attendee.organizer"
              :options="[
                { value: true, text: 'Organizador' },
                { value: false, text: 'Convidado' }
              ]"
              @change="updateJson"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="1"
          class="d-flex align-items-center justify-content-center"
        >
          <b-button
            v-if="index === 0"
            class="btn-attendee"
            title="Adicionar participante"
            @click="addAttendee()"
          >
            <i class="fas fa-plus px-0" style="color: #472282"></i>
          </b-button>
          <b-button
            v-if="index > 1"
            class="btn-attendee"
            title="Remover participante"
            @click="removeAttendee()"
          >
            <i class="fas fa-trash px-0" style="color: #472282"></i>
          </b-button>
        </b-col>
      </b-row>

      <h6>Permissões de convidados</h6>
      <b-row>
        <b-col cols="12" md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="guestsCanModify"
              inline
              @change="updateJson"
              >Modificar o evento</b-form-checkbox
            >
            <b-form-checkbox
              v-model="guestsCanInviteOthers"
              inline
              @change="updateJson"
              >Convidar participantes</b-form-checkbox
            >
            <b-form-checkbox
              v-model="guestsCanSeeOtherGuests"
              inline
              @change="updateJson"
              >Ver outros participantes</b-form-checkbox
            >
          </b-form-group>
        </b-col>
      </b-row>

      <h6>Lembretes</h6>
      <b-row>
        <b-col cols="12" md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="sendReminder"
              @change="handleSendReminderChange"
              inline
              >Enviar lembrete</b-form-checkbox
            >
            <b-form-checkbox
              v-model="reminders.useDefault"
              :disabled="!sendReminder"
              @change="updateJson"
              inline
              >Utilizar lembrete padrão</b-form-checkbox
            >
          </b-form-group>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import ApiService from "../../core/services/api.service";
import API_LINKS from "../pages/painel/automacao/api.links";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
export default {
  name: "TeleconsultaOptions",
  components: {},
  data() {
    return {
      formData: {
        url: process.env.VUE_APP_COMUNICANTE_API + "/google/calendar/event",
        method: "POST",
        headers: '{"Content-Type": "application/json"}',
        batchSize: 1,
        format: "{}",
        formatConfig: "",
        sendAs: "data",
        contentType: "application/json"
      },
      formatIsValidJson: true,
      headersIsValidJson: true,
      parsedJson: null,
      durationOptions: [
        { value: "00:15:00", text: "15 minutos" },
        { value: "00:30:00", text: "30 minutos" },
        { value: "00:45:00", text: "45 minutos" },
        { value: "01:00:00", text: "1 hora" },
        { value: "01:30:00", text: "1 hora e meia" },
        { value: "02:00:00", text: "2 horas" }
      ],
      max_participants: 2,
      summary: "",
      calendarId: "",
      description: "",
      date_start: "",
      date_end: "",
      duration: "01:00:00",
      calendars: [],
      guestsCanModify: false,
      guestsCanInviteOthers: false,
      guestsCanSeeOtherGuests: false,
      attendees: [
        {
          name: "",
          email: "",
          phone: "",
          organizer: true
        },
        {
          name: "",
          email: "",
          phone: "",
          organizer: false
        }
      ],
      reminders: {
        useDefault: false,
        overrides: []
      },
      sendReminder: false
    };
  },
  methods: {
    async submitForm() {
      const dataToSend = this.construirAxiosRequestConfig();

      try {
        const response = await axios.post("YOUR_BACKEND_URL_HERE", dataToSend);
        alert("Configurações enviadas com sucesso.");
      } catch (error) {
        console.error("Erro ao enviar configurações:", error);
        alert("Falha ao enviar configurações.");
      }
    },
    updateHeaders() {
      try {
        const headers = JSON.parse(this.formData.headers);
        headers["Content-Type"] = this.formData.contentType;
        this.formData.headers = JSON.stringify(headers);
        this.headersIsValidJson = true;
        this.formatJsonFormHeaders();
      } catch (e) {
        this.headersIsValidJson = false;
      }
      this.json["Teleconsulta"] = JSON.stringify(
        this.construirAxiosRequestConfig()
      );
    },
    updateJson() {
      this.formData.format = JSON.stringify({
        meeting: {
          access: this.access,
          provider: this.provider,
          moderator_code: this.moderatorCode,
          password: this.password,
          max_participants: this.max_participants ?? this.attendees.length
        },
        sli_token: this.sliToken,
        summary: this.summary,
        calendar_id: this.calendarId,
        description: this.description,
        start_date_time: this.date_start,
        end_date_time: this.date_end,
        duration: this.duration,
        attendees: this.attendees,
        reminders: this.reminders,
        guestsCanModify: this.guestsCanModify,
        guestsCanInviteOthers: this.guestsCanInviteOthers,
        guestsCanSeeOtherGuests: this.guestsCanSeeOtherGuests
      });

      this.json["Teleconsulta"] = JSON.stringify(
        this.construirAxiosRequestConfig()
      );
    },
    formatJsonFormFormat() {
      try {
        this.parsedJson = JSON.parse(this.formData.format);
        // this.formData.format = JSON.stringify(this.parsedJson, null, 2);
        this.formatIsValidJson = true;
      } catch (e) {
        this.formatIsValidJson = false;
      }
      this.updateJson();
    },
    formatJsonFormHeaders() {
      try {
        this.parsedJson = JSON.parse(this.formData.headers);
        // this.formData.headers = JSON.stringify(this.parsedJson, null, 2);
        this.headersIsValidJson = true;
      } catch (e) {
        this.headersIsValidJson = false;
      }
      this.updateJson();
    },
    construirAxiosRequestConfig() {
      return {
        requestConfig: {
          headers: JSON.parse(this.formData.headers),
          method: this.formData.method,
          url: this.formData.url
        },
        sendConfig: {
          sendAs: this.formData.sendAs,
          batchSize: this.formData.batchSize,
          format: this.formData.format ? this.formData.format : "{}",
          formatConfig: this.formData.formatConfig
            ? this.formData.formatConfig
            : "{}"
        }
      };
    },
    handleFocus(event) {
      this.$emit("focused", event.target.id);
    },
    loadCalendars(sli_token) {
      this.calendars = [];
      ApiService.get(
        API_LINKS.calendarios + "?sli_token=" + sli_token,
        "",
        false,
        {
          baseURL: process.env.VUE_APP_COMUNICANTE_API
        }
      )
        .then((response) => {
          this.calendars = response.data.data.map((v) => {
            return {
              text: v.summary,
              value: v.id
            };
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Aviso!",
            text: "Esse sistema não possui calendários configurados.",
            icon: "warning",
            confirmButtonText: "Ok"
          });
        });
    },
    handleSendReminderChange() {
      if (!this.sendReminder) {
        this.reminders.useDefault = false;
        this.reminders.overrides = [];
      } else {
        this.reminders.overrides = [
          { method: "popup", minutes: 10 },
          { method: "email", minutes: 60 }
        ];
      }
      this.updateJson();
    },
    removeAttendee() {
      this.attendees.pop();
      this.updateJson();
    },
    addAttendee() {
      this.attendees.push({
        name: "",
        email: "",
        phone: "",
        organizer: false
      });
      if (this.max_participants < this.attendees.length) {
        this.max_participants = this.attendees.length;
      }
      this.updateJson();
    },
    preencherForm(objJson) {
      objJson = JSON.parse(objJson.sendConfig.format);

      this.$emit("update:access", objJson.meeting.access);
      this.$emit("update:provider", objJson.meeting.provider);
      this.$emit("update:moderatorCode", objJson.meeting.moderator_code);
      this.$emit("update:password", objJson.meeting.password);
      this.$emit("update:sliToken", objJson.sli_token);

      this.summary = objJson.summary;
      this.calendarId = objJson.calendar_id;
      this.description = objJson.description;
      this.duration = objJson.duration;
      this.date_start = objJson.start_date_time;
      this.date_end = objJson.end_date_time;
      this.max_participants = objJson.meeting.max_participants;
      this.attendees = objJson.attendees;
      this.reminders = objJson.reminders;
      this.guestsCanInviteOthers = objJson.guestsCanInviteOthers;
      this.guestsCanModify = objJson.guestsCanModify;
      this.guestsCanSeeOtherGuests = objJson.guestsCanSeeOtherGuests;
      this.sendReminder = objJson.reminders.overrides.length > 0;
    }
  },
  created() {
    if (this.json["Teleconsulta"]) {
      const objJson = JSON.parse(this.json["Teleconsulta"]);
      this.preencherForm(objJson);
    }
  },
  props: {
    json: {
      required: true
    },
    sliToken: {
      required: true
    },
    access: {
      required: true
    },
    moderatorCode: {
      required: true
    },
    password: {
      required: true
    },
    provider: {
      required: true
    }
  },
  watch: {
    sliToken() {
      this.loadCalendars(this.sliToken);
    },
    access() {
      this.updateJson();
    },
    moderatorCode() {
      this.updateJson();
    },
    password() {
      this.updateJson();
    }
  }
};
</script>

<style scoped>
.teleconsulta-options {
  margin: 0 auto;
}
.scroll-container {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 15px; /* Evita que o conteúdo fique escondido pela barra de rolagem */
}
.btn-attendee {
  background-color: transparent;
  color: white;
}
</style>
